import {
    CategoryFromResponse,
    CategoryLookup,
    RefAccessMethod,
    RefCategory,
    RefCountry,
    ReferenceData,
    RefHall,
    RefPersonFunctions
} from "./ReferenceDataState"

// const STATIC_LANGUAGES = [
//     { id: "eng", label: "English", value: "En" },
//     { id: "deu", label: "German", value: "De" },
// ]

const STATIC_ACCESSMETHOD = [
    { id: "pb", label: "public", value: "Pb" },
    { id: "br", label: "backoffice request", value: "Br" }
]

function parseCategories(categoriesResponse: CategoryFromResponse[]): {
    categories: RefCategory[]
    categoriesMap: CategoryLookup
} {
    // Read the xml categories into a temporrary map
    const categoriesLookup: CategoryLookup = {}

    categoriesResponse.forEach((categoryResponse) => {
        const category = {
            id: categoryResponse.id,
            alias: categoryResponse.alias,
            name: categoryResponse.name,
            types: categoryResponse.types,
            parentId: categoryResponse.parentId,
            synthetic: categoryResponse.synthetic,
            code: categoryResponse.code,
            subCategories: []
        }
        categoriesLookup[category.id!] = category
    })

    // build the categories tree
    // Iterate over the response categories because they are sorted.
    // this way we get a sorted hierarchy of categories
    const categories = [] as RefCategory[]
    for (let category of categoriesResponse) {
        category = categoriesLookup[category.id!]
        if (!category.parentId) {
            // root nodes
            categories.push(category)
        } else {
            // sub categories are mapped to their parents
            const parent = categoriesLookup[category.parentId]
            if (parent) {
                parent.subCategories?.push(category)
            }
        }
    }

    return {
        categories: categories,
        categoriesMap: categoriesLookup
    }
}

// function parseLanguages(): RefLanguage[] {
//     return STATIC_LANGUAGES
// }

function parseAccessMethod(): RefAccessMethod[] {
    return STATIC_ACCESSMETHOD
}

function parseCountries(ctyList: Array<any>): RefCountry[] {
    return ctyList.map((country, index) => ({
        name: country.n || "",
        value: country.v || ""
    }))
}

function parseHalls(halls: Array<any>): RefHall[] {
    return halls.map((hall, index) => ({
        name: hall.n || "",
        value: hall.v || ""
    }))
}

function parsePersonFunction(personfunctions: Array<any>): RefPersonFunctions[] {
    return personfunctions.map((personFunction, index) => ({
        name: personFunction.name || "",
        value: personFunction.id || ""
    }))
}

export function parseResponse(response: any): ReferenceData {
    if (!response.initdata) return {} as ReferenceData
    return {
        // languages: parseLanguages(),
        accessMethod: parseAccessMethod(),
        countries: parseCountries(response.initdata.ctyList),
        halls: parseHalls(response.initdata.halls),
        personFunction: parsePersonFunction(response.initdata.personfunctions),
        ...parseCategories(response.initdata.categories)
    }
}
