import React, { useCallback, useMemo, useState } from "react"
import { Immutable } from "@hookstate/core"
import { useNotificationsDataState } from "../../../globalStates/NotificationDataState"
import { getActiveLanguage } from "../../../globalStates/LanguageState"
import { useReferenceDataState } from "../../../globalStates/ReferenceDataState"
import { TargetGroupElement } from "../../../globalStates/ExhibitorDataTypes"
import TreeCheck, {
    Checkbox,
    DownArrow,
    NodeText,
    RightArrow,
    TreeData,
    TreeItemData,
    TreeItemWrapper
} from "../../../components/TreeCheck"
import { TargetGroupFilterValue } from "../../../globalStates/TargetGroupFiltersState"
import { getElementLocalizedName } from "../../../globalStates/utils"
import branding from "../../../branding/branding"
//import SearchField from "../../../components/Search"

function isEligible(value: Immutable<TargetGroupFilterValue>, filter: string) {
    const eligible = value.name && value.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0

    return eligible
}

function makeNode(
    value: Immutable<TargetGroupFilterValue>,
    subNodes: TreeItemData[],
    selectedElements: Map<string, boolean>
): TreeItemData {
    return {
        id: value.id || "",
        rootId: "",
        text: getElementLocalizedName(value, getActiveLanguage(), "name") || "",
        code: value.id || "",
        selected: !!value.id && !!selectedElements.get(value.id),
        checked: false,
        relevant: false,
        parent: null,
        nodes: subNodes
    }
}

function mapValue2Node(
    value: Immutable<TargetGroupFilterValue>,
    filter: string,
    selectedElements: Map<string, boolean>,
    rootCategoryId: string | null
): TreeItemData | null {
    let currentNode: TreeItemData | null = null

    if (isEligible(value, filter)) {
        currentNode = makeNode(value, [], selectedElements)
        currentNode.relevant = !!filter

        return currentNode
    }

    return currentNode
}

function mapValues2Nodes(
    values: Immutable<TargetGroupFilterValue[]> | null,
    filter: string,
    selectedElements: Map<string, boolean>
): TreeData {
    const nodes: TreeItemData[] = []

    values?.forEach((value) => {
        const node = mapValue2Node(value, filter, selectedElements, value.id)
        if (node) {
            nodes.push(node)
        }
    })
    return { nodes }
}

function selectedElementsToMap(selInterests: Immutable<TargetGroupElement[]> | null): Map<string, boolean> {
    let selection = new Map()
    selInterests?.forEach((cat) => {
        selection.set(cat.id, true)
    })
    return selection
}

interface InterestsProps {
    id: string
    name: string
    selectedElements: TargetGroupElement[]
    values: Immutable<TargetGroupFilterValue[]>
    addElement: (id: string) => void
    removeElement: (id: string) => void
}

const TargetGroupElements: React.FC<InterestsProps> = ({ id, name, selectedElements, values, addElement, removeElement }) => {
    const notifState = useNotificationsDataState()
    //const [filter, setFilter] = useState("")
    const refDataState = useReferenceDataState()
    const selected = useMemo(() => selectedElementsToMap(selectedElements), [selectedElements])
    const root = useMemo(() => mapValues2Nodes(values, "", selected), [values, selected])
    const [expanded, setExpanded] = useState(false)

    const isSelected = (elementId: string): boolean => {
        return selectedElements.findIndex((element: TargetGroupElement) => element.id === elementId) > -1
    }

    const onSelectionChangedHandler = useCallback(
        (id: string, rootId: string, credits?: string) => {
            const toRemoveCheck: boolean = isSelected(id)

            toRemoveCheck ? removeElement(id) : addElement(id)
        },
        // eslint-disable-next-line
        [notifState, refDataState, root, selected]
    )

    return (
        <>
            <TreeItemWrapper className="targetGroupWrapper" unitsLeft={0}>
                <Checkbox id={id} className={selected.size > 0 ? "checked" : ""} onClick={() => {}} />
                <NodeText mainColor={branding.configuration.maincolor} onClick={() => setExpanded(!expanded)}>
                    {name}
                </NodeText>
                {expanded && <DownArrow />}
                {!expanded && <RightArrow />}
            </TreeItemWrapper>
            {expanded && (
                <TreeCheck showCode={false} rootNode={root} selection={selected} onSelectionChanged={onSelectionChangedHandler} />
            )}
        </>
    )
}

export default TargetGroupElements
