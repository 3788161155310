import React, { PropsWithChildren, useCallback, useState } from "react"
import styled from "styled-components"
import { State } from "@hookstate/core"
import { Validation } from "@hookstate/validation"
import { hasErrorsAll } from "../../globalStates/utils"
import ErrorBoundary from "../ErrorBoundary"
import { QrCodeTool, RemoveTool } from "./SectionTools"

const Wrapper = styled.div`
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 30px;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #d9d9d9;
    font-weight: bold;
    cursor: pointer;
`

const Title = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    line-height: 20px;
    color: #444650;
    &.invalid {
        color: #dc3545;
    }
`

const ActionBar = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;

    & button {
        width: 100px;
        margin: -5px 5px 0 0;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
    }
`

interface ContentProps {
    visible: boolean
}

//TODO add animation/transition
const Content = styled.div<ContentProps>`
    display: ${(props) => (props.visible ? "block" : "none")};
    // height:  ${(props) => (props.visible ? "auto" : "0px")};
    // transition: height 15s ease-out;
    padding: 20px 25px 5px 25px;
`

interface SectionProps {
    id: string
    title: string
    sideTitle?: string
    includeQrCode?: boolean
    customEndComponent?: React.JSX.Element
    collapsible?: boolean
    closed?: boolean
    opacity?: number
    onClickTitle?: () => void
    onClickQrCode?: () => void
    onClickRemove?: () => void
    entityState?: State<any, Validation>
}

const Section = React.forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
    (
        {
            id,
            title,
            sideTitle,
            includeQrCode,
            customEndComponent,
            collapsible = false,
            closed = false,
            opacity = 1,
            onClickTitle,
            onClickQrCode,
            onClickRemove,
            entityState,
            children
        },
        ref
    ) => {
        const isInvalid = hasErrorsAll(entityState)
        const [open, setOpen] = useState(!collapsible)

        const onClickHandler = useCallback(
            (event: React.MouseEvent<HTMLDivElement>) => {
                event.preventDefault()
                event.stopPropagation()
                if (closed) return

                if (collapsible) setOpen(!open)
                onClickTitle && onClickTitle()
            },
            [collapsible, closed, onClickTitle, open]
        )

        return (
            <Wrapper style={{ opacity }}>
                <Header ref={ref} onClick={onClickHandler}>
                    <Title className={isInvalid ? "invalid" : ""}>{title}</Title>
                    <ActionBar>
                        {customEndComponent}
                        {sideTitle && <Title>{sideTitle}</Title>}
                        {includeQrCode === true && onClickQrCode && !(Number(id) <= 0) && <QrCodeTool onClick={onClickQrCode} />}
                        {onClickRemove && <RemoveTool onClick={onClickRemove} />}
                    </ActionBar>
                </Header>
                {open && (
                    <ErrorBoundary>
                        <Content visible={open}>{children}</Content>
                    </ErrorBoundary>
                )}
            </Wrapper>
        )
    }
)

export default Section
