import { hookstate, Immutable, useHookstate } from "@hookstate/core"
import branding from "../branding/branding"
import { fetchDataRest } from "../backendServices/backendServices"
import { Localized } from "./ExhibitorDataTypes"

export interface TargetGroupFiltersData {
    targetGroupFilters: Immutable<TargetGroupFilter[]>
}

export interface TargetGroupFiltersState {
    init(): Promise<any>
    getTargetGroupFilters(): Immutable<TargetGroupFilter[]>
}

export interface TargetGroupFilter {
    id: string
    type: "INTEREST" | "COUNTRY" | "QUESTION"
    name: string
    localized: Localized
    values: TargetGroupFilterValue[]
}

export interface TargetGroupFilterValue {
    id: string
    name: string
    localized: Localized
}

async function loadTargetGroupFilters() {
    const response = await fetchDataRest(`/selfservice/0/${branding.configuration.topicName}/targetgroupfilter`, {}, "GET")

    return parseResponse(response)
}

export const initialState = {
    targetGroupFilters: []
}

const subelementPackagesState = hookstate<TargetGroupFiltersData>(initialState)

export function useTargetGroupFiltersState(): TargetGroupFiltersState {
    const state = useHookstate(subelementPackagesState)

    return {
        init() {
            const tgfData = loadTargetGroupFilters()

            tgfData.then((result) => {
                state.set({ ...result })
            })

            return tgfData
        },

        getTargetGroupFilters() {
            return state.targetGroupFilters.get().map((tgfState) => tgfState)
        }
    }
}

function parseTargetGroupFilters(filters: Array<any>): TargetGroupFilter[] {
    return filters.map((filter, index) => ({
        id: filter.id,
        type: filter.type,
        name: filter.name,
        localized: filter.localized,
        values: filter.values
    }))
}

export function parseResponse(response: any): TargetGroupFiltersData {
    if (!response.targetGroupFilters) return { targetGroupFilters: [] } as TargetGroupFiltersData

    return {
        targetGroupFilters: parseTargetGroupFilters(response.targetGroupFilters)
    }
}
