import React from 'react';
import Feedback from 'react-bootstrap/esm/Feedback';
import BSFormCheck from 'react-bootstrap/FormCheck';
import WarningIcon from '../../images/warning.svg'

interface RadioFieldProps {
    id?: string
    label: string
    name: string
    value: string
    radioValue: string
    onChange?: (value: string) => void
    isInvalid?: boolean
    disabled?: boolean
}

const RadioField: React.FC<RadioFieldProps> = ({ id, label, name, value, radioValue, isInvalid, disabled, onChange }) => {
    return (
        <BSFormCheck className="form-check-inline">
            <BSFormCheck.Input id={id} type="radio" name={name} checked={value === radioValue} isInvalid={isInvalid} disabled={disabled} onChange={() => onChange?.(radioValue)} />
            <BSFormCheck.Label htmlFor={id}>{label}</BSFormCheck.Label>
            <Feedback type="invalid"><img src={WarningIcon} alt="warning" /></Feedback>
        </BSFormCheck>
    )
}

export default RadioField
