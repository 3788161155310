import React, { FunctionComponent, useCallback, useEffect, useState } from "react"
import { ValidationError } from "@hookstate/validation"
import { useExhibitorDataState } from "../../globalStates/ExhibitorDataState"
import { translateValidationErrors } from "../../globalStates/utils"
import { useLanguageState } from "../../globalStates/LanguageState"

export interface FeedbackInput {
    error: string
}

interface InputFieldProps {
    name: string
    component: FunctionComponent<{ error: string; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }>
    validate?: () => ValidationError[]
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const InputField: React.FC<InputFieldProps> = ({ name, component, validate, onChange, ...rest }) => {
    if (!component) {
        throw new Error("Must provide the component prop")
    }
    const branding = useLanguageState().getStrings()
    const [error, setError] = useState<string>("")
    const exiState = useExhibitorDataState()
    const onChangeComponent = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event)
            const errors = validate?.() || exiState.validateField(name)
            if (errors && errors.length > 0) {
                setError(translateValidationErrors(errors, branding))
            } else setError("")
        },
        [branding, exiState, name, onChange, validate]
    )
    useEffect(() => {
        const errors = validate?.() || exiState.validateField(name)
        if (errors && errors.length > 0) {
            setError(translateValidationErrors(errors, branding))
        } else setError("")
    }, [branding, exiState, name, onChange, validate])

    return React.createElement(component, { error, ...rest, onChange: onChangeComponent })
}

export default InputField
