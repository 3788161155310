import { hookstate, Immutable, useHookstate } from "@hookstate/core"
import branding from "../branding/branding"
import { fetchDataRest } from "../backendServices/backendServices"
import { Localized } from "./ExhibitorDataTypes"

export interface SubelementPackagesData {
    subelementPackages: Immutable<SubelementPackage[]>
}

export interface SubelementPackagesState {
    init(): Promise<any>
    getSubelementPackages(): Immutable<SubelementPackage[]>
}

export interface SubelementPackage {
    id: string
    entity: string
    name: string
    type: string
    subelementContingents: any
    localized: Localized
}

async function loadSubelementPackages() {
    const response = await fetchDataRest(`/servicekeys/v2/subelementpackage/topic/${branding.configuration.topicName}`, {}, "GET")

    return parseResponse(response)
}

export const initialState = {
    subelementPackages: []
}

const subelementPackagesState = hookstate<SubelementPackagesData>(initialState)

export function useSubelementPackagesState(): SubelementPackagesState {
    const state = useHookstate(subelementPackagesState)

    return {
        init() {
            const spData = loadSubelementPackages()

            spData.then((result) => {
                state.set({ ...result })
            })

            return spData
        },

        getSubelementPackages() {
            return state.subelementPackages.get().map((spState) => spState)
        }
    }
}

function parseSubelementPackages(packages: Array<any>): SubelementPackage[] {
    return packages.map((sp, index) => ({
        id: sp.id,
        entity: sp.entity,
        name: sp.name,
        type: sp.type,
        subelementContingents: sp.subelementContingents,
        localized: sp.localized
    }))
}

export function parseResponse(response: any): SubelementPackagesData {
    if (!response.subelementPackages) return { subelementPackages: [] } as SubelementPackagesData

    return {
        subelementPackages: parseSubelementPackages(response.subelementPackages)
    }
}

/*async function loadTargetGroupFilters() {
    return await fetchDataRest(`/selfservice/0/${branding.configuration.topicName}/targetgroupfilter`, {}, "GET")
}*/
