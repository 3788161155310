import React from "react"
import RemoveIcon from "../../images/remove.svg"
import QrCodeIcon from "../../images/qrcode.svg"
import Icon from "../Icon"

interface RemoveToolProps {
    onClick: () => void
}

export const RemoveTool: React.FC<RemoveToolProps> = ({ onClick }) => <Icon src={RemoveIcon} onClick={onClick} />

interface QrCodeToolProps {
    onClick: () => void
}

export const QrCodeTool: React.FC<QrCodeToolProps> = ({ onClick }) => (
    <Icon
        src={QrCodeIcon}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()

            onClick()
        }}
    />
)
