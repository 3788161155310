import React, { useCallback } from "react"
import ModalBS from "react-bootstrap/Modal"
import Button from "../Button"
import styled from "styled-components"
import branding from "../../branding/branding"

const Wrapper = styled.div`
    button,
    button:focus,
    button:active:focus,
    button.inverse:not(:disabled):not(.disabled):active:focus {
        color: ${branding.configuration.maincolor};
        background-color: #fff;
        border: 1px solid ${branding.configuration.maincolor};
        border-radius: 5px;
        width: 100px;
        margin: 0 10px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }
    button:focus,
    button:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }
    button:not(:disabled):not(.disabled):active:focus {
        color: #fff;
        background-color: ${branding.configuration.maincolor};
        border: 1px solid ${branding.configuration.maincolor};
    }
    button:hover {
        color: ${branding.configuration.maincolor};
        background-color: #fff;
        border: 1px solid ${branding.configuration.maincolor};
    }
    button.inverse {
        color: #fff;
        background-color: ${branding.configuration.maincolor};
        border: 1px solid ${branding.configuration.maincolor};
    }
    button.inverse:hover {
        color: #fff;
        background-color: ${branding.configuration.maincolor};
        border: 1px solid ${branding.configuration.maincolor};
    }

    button.btn-link {
        border: none;
    }
`

interface ModalProps {
    name: string
    title: string
    titleSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
    modalSize?: "sm" | "lg" | "xl"
    visible: boolean
    onClose?: () => void
    children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ name, title, titleSize, modalSize, visible, onClose, children }) => {
    // const modalState = useSelector(state => state.modals[name]);
    // const dispatch = useDispatch();
    // const hideModal = useCallback(() => dispatch({ type: 'HIDE_MODAL', name }), []);
    const hideModal = useCallback(() => {}, [])
    // const prevModalState = usePrevious(modalState);
    // onClose && isModalClosed(modalState, prevModalState) && onClose();
    return (
        <ModalBS backdrop scrollable show={visible} onHide={hideModal} size={modalSize}>
            <ModalBS.Header closeButton onHide={onClose}>
                <ModalBS.Title as={titleSize ?? "h4"}>{title}</ModalBS.Title>
            </ModalBS.Header>
            <Wrapper>{children}</Wrapper>
        </ModalBS>
    )
}

interface OkButtonProps {
    name: string
    label?: string
    disabled?: boolean
    onOk: () => void
}

export const OkButton: React.FC<OkButtonProps> = ({ name, label, onOk, disabled }) => {
    const onClickHandler = useCallback(() => {
        onOk && onOk()
    }, [onOk])

    return <Button id="ok" inverse={true} onClick={onClickHandler} caption={label || "OK"} disabled={disabled} />
}

interface CancelButtonProps {
    name: string
    label?: string
    disabled?: boolean
    onCancel: () => void
}

export const CancelButton: React.FC<CancelButtonProps> = ({ name, label, onCancel, disabled }) => {
    const onClickHandler = useCallback(() => {
        onCancel && onCancel()
    }, [onCancel])

    return <Button id="cancel" inverse={false} onClick={onClickHandler} caption={label || "Cancel"} disabled={disabled} />
}

interface SaveButtonProps {
    name: string
    label?: string
    disabled?: boolean
    onSave: () => void
}

export const SaveButton: React.FC<SaveButtonProps> = ({ name, label, onSave, disabled }) => {
    const onClickHandler = useCallback(() => {
        onSave && onSave()
    }, [onSave])

    return <Button id="save" inverse={true} onClick={onClickHandler} caption={label || "Save"} disabled={disabled} />
}

export default Modal
