import React from "react"
import { styled } from "styled-components"
import BSButton from "react-bootstrap/Button"

const Icon = styled.img`
    margin-right: 10px;
`

interface ButtonProps {
    id?: string
    caption: string
    icon?: string
    inverse: boolean
    disabled?: boolean
    onClick: () => void
}

const Button: React.FC<ButtonProps> = ({ id, caption, icon, inverse, disabled, onClick }) => {
    return (
        <BSButton id={id} className={inverse ? "inverse" : ""} disabled={disabled} onClick={onClick}>
            {icon && <Icon src={icon} alt="icon" />} {caption}
        </BSButton>
    )
}

export default Button
