import React from 'react'

interface IconProps {
    src: string
    onClick?: (event: React.MouseEvent<HTMLImageElement>) => void
}

const Icon: React.FC<IconProps> = ({ src, onClick }) => {
    return (
        <img src={src} alt="" onClick={onClick} />
    )
}

export default Icon