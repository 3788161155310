import React from "react"
import styled from "styled-components"

const PageContentsWrapper = styled.div`
    width: 100%;
    padding: 30px, 25px;
    & > * {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`

const Description = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    white-space: break-spaces;
`

interface PageContentsProps {
    description: string
    children: React.ReactNode
}

const PageContents: React.FC<PageContentsProps> = ({ description, children }) => {
    return (
        <PageContentsWrapper>
            <Description>{description}</Description>
            {children}
        </PageContentsWrapper>
    )
}

export default PageContents
