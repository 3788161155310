import React from 'react'
import { ReferenceDataState, useReferenceDataState } from './ReferenceDataState';

export type ReferenceDataType = {
    refDataState: ReferenceDataState
}

export function withReferenceDataState<T>(WrappedComponent: React.ComponentType<T & ReferenceDataType>) {
    const ComponentWithRefState = (props: T) => {
        const refDataState = useReferenceDataState()
        return <WrappedComponent {...props} refDataState={refDataState} />;
    };

    ComponentWithRefState.whyDidYouRender = {
        logOnDifferentValues: true,
        customName: WrappedComponent.displayName
    }
    return ComponentWithRefState;
}
