import { hookstate, useHookstate } from "@hookstate/core";
import branding from "../branding/branding";

export interface Notification {
    type: string
    title: string
    text: string
}

interface NotificationData {
    notifications: Notification[]
}

const notificationsDataState = hookstate<NotificationData>({ notifications: [] })


export function useNotificationsDataState() {
    const state = useHookstate(notificationsDataState)

    return ({
        getNotifications() {
            return state.notifications.get()
        },

        addSuccess(message: string) {
            state.notifications.merge([{
                type: 'success',
                title: branding.configuration.validationMessages.successMessageTitle,
                text: message
            }])
        },

        addError(message: string) {
            state.notifications.merge([{
                type: 'error',
                title: branding.configuration.validationMessages.errorMessageTitle,
                text: message
            }])
        },

        addWarning(message: string) {
            state.notifications.merge([{
                type: 'warning',
                title: branding.configuration.validationMessages.warningMessageTitle,
                text: message
            }])
        }
    })
}