import React, { useCallback } from "react"
import styled from "styled-components"
import branding from "../../branding/branding"

interface SwitchWrapperProps {
    mainColor: string
}
const SwitchWrapper = styled.label<SwitchWrapperProps>`
    position: relative;
    display: inline-block;
    width: 25px;
    height: 15.62px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + .slider {
        background-color: ${(props) => props.mainColor || "#6DD400"};
    }

    input:focus + .slider {
        box-shadow: 0 0 1px ${(props) => props.mainColor || "#6DD400"};
    }

    input:checked + .slider:before {
        transform: translateX(9px);
    }
`

const Slider = styled.div`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d9d9d9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 15.63px;

    &:before {
        position: absolute;
        content: "";
        height: 11.46px;
        width: 11.46px;
        left: 2.08px;
        bottom: 2.08px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    &.disabled {
        background-color: #e5e5e5 !important;
    }
`

interface ToggleProps {
    id: string
    value?: boolean
    readonly?: boolean
    disabled?: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ToggleSwitch: React.FC<ToggleProps> = ({ id, value, readonly, disabled, onChange }) => {
    const onChangeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!readonly) onChange?.(event)
        },
        [onChange, readonly]
    )

    return (
        <SwitchWrapper mainColor={branding.configuration.maincolor}>
            <input id={id} type="checkbox" readOnly={readonly} disabled={disabled} checked={value} onChange={onChangeHandler} />
            <Slider className={`slider ${disabled && "disabled"}`} />
        </SwitchWrapper>
    )
}

export default ToggleSwitch
