import React from "react"
import BSForm from "react-bootstrap/Form"
import styled from "styled-components"
import InputField from "./InputField"
import BSFormControl from "react-bootstrap/FormControl"

const RemainingText = styled.div<{ hasErrorMessage: boolean }>`
    position: absolute;
    right: 30px;
    bottom: ${(props) => (props.hasErrorMessage ? "45px" : "30px")};
    color: #bdbdbd;
    font-size: 14px;
`

interface TextAreaFieldProps {
    name: string
    label: string
    placeholder?: string
    value?: string
    required?: boolean
    readonly?: boolean
    rows?: number
    error?: string
    limit?: number
    style?: React.CSSProperties
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const TextAreaField0: React.FC<TextAreaFieldProps> = ({
    name,
    label,
    placeholder,
    value,
    required,
    readonly,
    rows,
    error,
    limit,
    style,
    onChange
}) => {
    return (
        <BSForm.Group>
            <BSForm.Label>
                {label}
                {required ? "*" : ""}
            </BSForm.Label>
            <BSForm.Control
                id={name}
                name={name}
                as="textarea"
                placeholder={placeholder}
                value={value}
                readOnly={readonly}
                maxLength={limit}
                rows={rows}
                style={style}
                onChange={onChange}
                isValid={!error}
                isInvalid={!!error}
            />
            <RemainingText hasErrorMessage={!!error}>{`(${(value || "").length}/${limit})`}</RemainingText>
            <BSFormControl.Feedback type="invalid">{error}</BSFormControl.Feedback>
        </BSForm.Group>
    )
}

const TextAreaField: React.FC<any> = (props: any) => <InputField component={TextAreaField0} {...props} />
export default TextAreaField
