import { State } from '@hookstate/core'
import { Validation } from '@hookstate/validation'
import { useCallback, useState } from 'react'
import { getElementFieldValue, makeSetHandler, makeValidationHandler } from '../globalStates/utils'

export function fieldNameByLang(rootName: string, lang: string): string {
    return lang === 'En' ? rootName : `${rootName}${lang}`
}

export function useLanguageSwitch(initialLanguage: string) {
    const [currentLanguage, setCurrentLanguage] = useState(initialLanguage)

    const onChangeLanguageHandler = useCallback((language: string) => {
        setCurrentLanguage(language)
    }, [])

    return { currentLanguage, onChangeLanguageHandler }
}

export function getElementFieldValueWithLanguage<T>(elementState: State<T, Validation>, fieldBaseName: keyof State<T, Validation>, currentLanguage: string) {
    const fieldName = fieldNameByLang(fieldBaseName as string, currentLanguage) as keyof State<T, Validation>
    return getElementFieldValue(elementState, fieldName)
}

export function makeSetHandlerWithLanguage<T>(elementState: State<T, Validation>, fieldBaseName: keyof State<T, Validation>, currentLanguage: string) {
    const fieldName = fieldNameByLang(fieldBaseName as string, currentLanguage) as keyof State<T, Validation>
    return makeSetHandler(elementState, fieldName)
}

export function makeValidationHandlerWithLanguage<T>(elementState: State<T, Validation>, fieldBaseName: keyof State<T, Validation>, currentLanguage: string) {
    const fieldName = fieldNameByLang(fieldBaseName as string, currentLanguage) as keyof State<T, Validation>
    return makeValidationHandler(elementState, fieldName)
}
